@import 'src/variables';

.collaborator-card {
    padding: 0 !important;
    padding-bottom: 20px;
    overflow: hidden;
    width: 29%;
    max-width: 200px;
    min-width: 200px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    background: #fefefe;
    border-radius: 10px;
    position: relative;

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        flex: 1;
        padding: 10px;
    }
    .img {
        width: 100%;
        min-height: 220px;
    }
    .image {
        background-image: url(https://storage.googleapis.com/our-foundry-289307.appspot.com/DEV/collaborator/6334cbb….jpg?GoogleAccessId=firebase-adminsdk-thwqg%40our-foundry-289307.iam.gserviceaccount.com&Expires=1666527718&Signature=GtgT2kEcoS%2BV%2BLtG2nkeqLczWkPsxDIlYBZfdBeXy3aMdvw%2FDDq%2B3h9KZL%2FVwisdOXj93fTcmcUzJ%2BbApso%2FjWSHwZW%2BbvaONnhHBt%2B49xc98SDI9rQKP6tLGstbLiNMQKJ2UPSkjF4wwjIvRuuypVCXgLpzZ9zwD3mJONaRwMtFEY7Fp6L648aryFje2TQy%2F9FF4jVBaibpyFxd50kFk3zyA8kBiW4fYKvJ%2Fr1WSYIZmNTQ2fYZE1EsuLOobcv9l9JLencrqVOATk0oZ8bkIchz%2BwySZ1foPdhDKs7DlmTHbUxmQ%2FaFKuqneDPwtE%2FwCSEOhD5MwS8kj%2Fd%2FHj3d5Q%3D%3D);
        background-size: cover;
        height: 250px;
        background-repeat: no-repeat;
        background-position: top center;
        width: 100%;
    }
    .name {
        text-transform: uppercase;
        font-weight: 600;
    }
    .title {
        font-size: 0.875rem;
        margin-top: 5px;
    }
    .email {
        a {
            font-size: 0.75rem;
            margin-top: 5px;
            color: $secondary-color;
        }
    }
    .menu {
        position: absolute;
        top: 36px;
        right: 0;
        background: #fff;
        box-shadow: 1px 3px 5px 0px #6e6e6ea8;
        border-radius: 4px;
        padding: 10px 0;
        display: none;
        z-index: 1;
        &.open {
            display: block;
        }

        .menu-item {
            cursor: pointer;
            padding: 10px 10px;
            &:hover {
                background: $color-grey-light;
            }
        }
    }
    .arrow {
        position: absolute;
        top: 29px;
        right: 11px;
        height: 0px;
        width: 0px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fff;
        display: none;
        z-index: 1;
        &.open {
            display: block;
        }
    }
    .option-menu {
        position: absolute;
        top: 15px;
        right: 10px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        width: 25px;
        height: 15px;
        cursor: pointer;
        color: $color-label;
    }

    &.big {
        & {
            width: 250px;
            height: 500px;
            max-width: 250px;
            max-height: 500px;
        }
        .img {
            height: 350px;

            .image {
                height: 350px;
                max-height: 350px;
            }
        }
    }
}
