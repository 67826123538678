@import 'src/variables';

.who-we-are {
    display: flex !important;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0px -2px 8px #00000085;
    background: #fefefe;
    flex-wrap: wrap;
    text-align: center;
    color: $main-color;

    h2 {
        margin-top: 20px;
    }
    .left {
        padding: 15px;
    }
    .left,
    .right {
        min-width: 250px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            width: 100%;
        }

        p {
            margin-bottom: 20px;
            font-size: 1.125rem;
            max-width: 600px;
            font-weight: 500;
            text-align: justify;
        }
    }
    .right {
        background: url('../../assets/images/magasin2.jpeg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 350px;
    }

    @media screen and (max-width: $mobile-size) {
        .left,
        .right {
            max-width: 100%;
        }
        video {
            max-width: 85vw !important;
            width: 85vw !important;
        }

        blockquote br {
            display: none;
        }
    }
}
