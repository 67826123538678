@import 'src/variables';

.lang-selector {
    color: $color-label;
    font-size: 0.875rem;
    .selected {
        color: $secondary-color;
        font-weight: 600;
        pointer-events: none;
    }
    span {
        pointer-events: unset;
        cursor: pointer;
    }
    & > * {
        margin: 0 5px;
    }
}
