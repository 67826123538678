@import 'src/variables';
#banner {
    min-height: calc(100vh - 5em);
    display: flex;
    justify-content: space-between;
    // background-position: center;
    // background-size: cover;
    position: relative;
    flex-direction: column;
    box-shadow: inset 0px -2px 8px #00000085;
    background: #fff;
    align-items: center;
    overflow: hidden;

    h1 {
        padding-top: 20px !important;
    }

    .van {
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        position: absolute;
        bottom: 150px;
        & > img {
            width: 25%;
            transition: all 1s;
            &:first-child {
                transform: scaleX(-1);
            }
        }
        &.init > img {
            width: 10px;
            &:first-child {
                transform: scale(-0.5) translate(80vw);
            }
            &:last-child {
                transform: scale(0.5) translate(80vw);
            }
        }
    }

    .img-wrapper {
        padding: 20px;
        margin: 0;
        z-index: 1;
        justify-content: space-around;
        flex-wrap: wrap;
        max-width: 630px;
        // background: #f9e4df;

        // border-bottom: solid 3px #ed5929;
        // border-top: solid 3px #ed5929;

        & > div {
            max-width: 90px;
            min-width: 90px;
            margin-bottom: 30px;
            img {
                max-height: 4em;
                max-width: 6em;
            }
        }
    }

    .content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background: rgba(255, 255, 255, 0.5);
        max-width: 900px;
        padding: 20px;
        text-align: center;
        max-width: 85%;
        flex: 1;
        justify-content: space-evenly;
    }

    .sponsor {
        max-width: 800px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > div {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            margin: 15px;
            justify-content: center;
            img {
                width: 75px;
            }
        }
    }

    h1 {
        font-size: 2.5rem;
        line-height: 1em;
        margin-bottom: 30px;
        padding: 0;
        font-variant: small-caps;
        font-weight: 700;
        color: #0b1e3a;
    }
    p {
        font-size: 1.125rem;
        margin-bottom: 1.75em;
        color: $secondary-color;
        font-weight: 500;
        text-align: justify;
        text-shadow: 0 0 6px black;
    }
}

.sponsor-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
    z-index: 10;
    img {
        height: 40px;
        width: auto;
        margin: 10px;
    }
}
@media screen and (max-width: 980px) {
    #banner {
        justify-content: inherit;
        .van img {
            width: 40% !important;
        }
        .img-wrapper {
            width: 100%;

            & > div {
                max-width: 100px;
                min-width: 100px;

                & > img {
                    max-height: 4em;
                    // min-height: 4em;
                }
            }
        }

        #banner h1 {
            font-size: 2.25em;
        }
    }
    .sponsor-container img {
        height: 35px;
    }
}

@media screen and (max-width: 980px) {
    #banner {
        h1 {
            font-size: 2em;
        }
        p {
            font-size: 1em;
        }
        .van {
            bottom: 50px;
            position: static;
        }
        .img-wrapper {
            flex-wrap: wrap;
            & > div {
                max-width: 75px;
                min-width: 75px;
                img {
                    min-height: inherit;
                    max-height: 50px;
                }
            }
        }
    }
    .sponsor > div {
        width: 50px;
        height: 75px;
        img {
            width: 50px;
        }
    }
}
