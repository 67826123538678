@import 'src/variables';
.psa-is {
    display: flex !important;
    justify-content: center;
    align-content: center;
    background-position: center;
    background-size: cover;
    position: relative;
    text-align: justify;
    flex-wrap: wrap-reverse;

    .line-container {
        height: 210px;
    }

    .left,
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px;
        // min-width: 50%;
        min-width: 300px;

        p {
            max-width: 500px;
        }

        .video-container {
            display: flex;
            justify-content: center;
            width: 80%;
            video {
                width: 100%;
            }
        }
    }

    h2 {
        margin-top: 70px;
        font-size: 2rem;
        line-height: 1em;
        margin-bottom: 30px;
        padding: 0;
        color: #fff;
        font-weight: 700;
    }

    .right {
        p,
        div {
            font-size: 1rem;
            color: #ededed;
            font-weight: 500;
            max-width: 500px;
            // text-shadow: 0 0 6px black
        }
    }

    p {
        text-align: justify;
        margin-bottom: 1.75em;
    }
    div {
        text-align: left;
        margin-bottom: 10px;
        width: 100%;
    }
    .content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        max-width: 70%;
        margin: auto;
    }
}
