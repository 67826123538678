@import 'src/variables';
.second-section {
    display: flex !important;
    justify-content: center;
    align-content: center;
    background-position: center;
    background-size: cover;
    position: relative;
    text-align: center;
    flex-wrap: wrap-reverse;

    .left,
    .right {
        padding: 15px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            max-width: 500px;
            font-weight: 500;
        }
    }

    // box-shadow: inset 0px 0px 8px #00000085;

    // &::before {
    //     content: ' ';
    //     top: 0;
    //     left: -25%;
    //     z-index: 0;
    //     width: 150%;
    //     height: 30px;
    //     transform: rotate(-2deg) translateY(50%);
    //     transform-origin: 0 0;
    //     position: absolute;
    //     background: $main-color;
    // }

    h2 {
        margin-top: 70px;
        font-size: 2rem;
        line-height: 1em;
        margin-bottom: 30px;
        padding: 0;
        color: #fff;
        font-weight: 700;
    }
    .img-container {
        position: relative;
        img {
            width: 300px;
        }
        .warehouse {
            width: 8px;
            height: 8px;
            background: $secondary-color;
            border-radius: 50%;
            position: absolute;
            left: 45%;
            top: 36%;
            z-index: 1;
        }
        .arrow {
            color: #fff;
            position: absolute;
            transition: all 0.5s;
            animation-duration: 3s;
            animation-iteration-count: infinite;
            font-size: 22px;
            text-shadow: #80808070 -4px 6px 7px;
            &.brabant-f {
                animation-name: animation-brabant-f;
                transform: rotate(-20deg);
            }
            &.liege {
                animation-name: animation-liege;
                transform: rotate(12deg);
            }
            &.hainaut {
                animation-name: animation-hainaut;
                transform: rotate(135deg);
            }

            &.flandre-occ {
                animation-name: animation-flandre-occ;

                transform: rotate(-162deg);
            }
            &.anvers {
                animation-name: animation-anvers;
                transform: rotate(-82deg);
            }
            &.luxembourg {
                animation-name: animation-luxembourg;
                transform: rotate(53deg);
            }
        }
    }
    p {
        font-size: 1.125rem;
        margin-bottom: 1.75em;
        color: #ededed;
        font-weight: 500;
        text-align: justify;
        text-shadow: 0 0 6px black;
    }
    .content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        max-width: 70%;
        margin: auto;
    }
}

@keyframes animation-brabant-f {
    0% {
        left: 46.1%;
        top: 32.5%;
    }
    50% {
        left: 64.1%;
        top: 28.5%;
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    90% {
        left: 46.1%;
        top: 32.5%;
        opacity: 0;
    }
    100% {
        left: 46.1%;
        top: 32.5%;
        opacity: 1;
    }
}

@keyframes animation-hainaut {
    0% {
        left: 40%;
        top: 36.7%;
    }
    50% {
        left: 31%;
        top: 45%;
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    90% {
        left: 40%;
        top: 36.7%;
        opacity: 0;
    }
    100% {
        left: 40%;
        top: 36.7%;
        opacity: 1;
    }
}

@keyframes animation-flandre-occ {
    0% {
        left: 40%;
        top: 33%;
    }
    50% {
        left: 15%;
        top: 27%;
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    90% {
        left: 40%;
        top: 33%;
        opacity: 0;
    }
    100% {
        left: 40%;
        top: 33%;
        opacity: 1;
    }
}

@keyframes animation-anvers {
    0% {
        left: 43.1%;
        top: 30.7%;
    }
    50% {
        left: 45.1%;
        top: 22.7%;
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    90% {
        left: 43.1%;
        top: 30.7%;
        opacity: 0;
    }
    100% {
        left: 43.1%;
        top: 30.7%;
        opacity: 1;
    }
}

@keyframes animation-liege {
    0% {
        left: 47%;
        top: 34.7%;
    }
    50% {
        left: 71%;
        top: 42%;
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    90% {
        left: 47%;
        top: 34.7%;
        opacity: 0;
    }
    100% {
        left: 47%;
        top: 34.7%;
        opacity: 1;
    }
}

@keyframes animation-luxembourg {
    0% {
        left: 45.3%;
        top: 37%;
    }
    50% {
        left: 69%;
        top: 60%;
        opacity: 1;
    }
    51% {
        opacity: 0;
    }
    90% {
        left: 45.3%;
        top: 37%;
        opacity: 0;
    }
    100% {
        left: 45.3%;
        top: 37%;
        opacity: 1;
    }
}
