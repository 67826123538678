@import 'src/variables';
@import 'src/assets/icons/icons';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: rgba(172, 175, 174, 0.11);
}
#root,
.App {
    height: 100%;
    width: 100%;
}
.plus-icon {
    background: $secondary-color;
    font-size: 1rem;
    padding: 10px;
    border-radius: 200px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    box-shadow: 2px 3px 13px #0000003d;
}

.card {
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 30px 0 rgba(84, 84, 84, 0.34);
}
input:disabled {
    background: #c2c2c2;
    cursor: not-allowed;
}

.errors-input {
    font-size: 0.75rem;
    color: $color-red;
    pointer-events: none;
}
button {
    background: $secondary-color;
    box-shadow: 0px 2px 7px #45454566;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;

    &.grey {
        background: $color-label;
    }
    &.blue {
        background: $main-color;
    }
    &.red {
        background: $color-red-dark;
    }
    &:disabled {
        opacity: 0.5;
    }
}
.breadcrumb {
    display: flex;
    margin-bottom: 20px;
    color: $color-label;
    align-items: center;

    a {
        pointer-events: none;
        &.clickable {
            pointer-events: unset;
        }
    }
    & > * {
        margin: 0 5px;
        color: $color-label;
        text-decoration: none;
        &:last-child {
            font-weight: 700;
        }
    }
}
.button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;

    &.center {
        justify-content: center;
    }
}
.folder {
    background: #c2d3dc;
    min-width: 150px;
    min-height: 100px;

    margin-top: 8px;
    width: 88px;
    height: 61px;
    border-radius: 0 6px 6px;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    box-shadow: 0 4px 30px 0 rgba(84, 84, 84, 0.18);
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
    }

    &::before {
        background-color: #b0b7bd;
        top: -8px;
        left: 0;
        width: 32px;
        height: 8px;
        border-radius: 6px 0 0;
    }
    &::after {
        border: 8px solid transparent;
        border-bottom-color: #b0b7bd;
        top: -16px;
        left: 24px;
        width: 0;
        height: 0;
    }
    &.green {
        background: #a3d4ae;
        &::before {
            background-color: #83ad8d;
        }
        &::after {
            border-bottom-color: #83ad8d;
        }
    }
}

.invisible {
    display: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    tr td {
        text-align: center;
    }
}

.ReactModalPortal {
    z-index: 8000;
    position: relative;
}

.line-container {
    width: 100%;
    overflow: hidden;
    position: absolute;
    height: 200px;
    left: 0;
    pointer-events: none;

    &.blue {
        top: -200px;
        div {
            content: ' ';
            bottom: -120px;
            left: -25%;
            z-index: 0;
            width: 150%;
            height: 120px;
            transform: rotate(-1.25deg);
            transform-origin: 0 0;
            position: absolute;
            background: $main-color;
        }
    }

    &.orange {
        bottom: 0;
        div {
            bottom: 0;
            left: -25%;
            z-index: 0;
            width: 150%;
            height: 100px;
            transform: rotate(-2deg) translateY(100%);
            transform-origin: 0 0;
            position: absolute;
            background: $secondary-color;
        }
    }
    @media screen and (max-width: $mobile-size) {
        & {
            &.orange {
                div {
                    bottom: -80px;
                    transform: rotate(-3deg);
                }
            }
            &.blue {
                top: -190px;
                div {
                    height: 135px;
                    bottom: -110px;
                    transform: rotate(-1.5deg);
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-size) {
    html,
    body {
        font-size: 14px;
    }
}
