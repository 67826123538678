@import 'src/variables';

.team-section {
    // display: flex !important;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // box-shadow: inset 0px -2px 8px #00000085;
    background: $main-color;
    flex-wrap: wrap-reverse;
    text-align: center;

    .left {
        min-height: 350px;
        color: #fff;
        h2 {
            color: #fff;
        }
    }
    .left,
    .right {
        min-width: 300px;
        flex: 1;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .icon-right {
            display: inline-block;
            transform: rotate(270deg) translate(-1px, 5px);
        }

        p {
            margin-bottom: 40px;
            font-size: 1.125rem;
            max-width: 900px;
            text-align: justify;
            max-width: 600px;
        }
    }
    .card-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 600px;
        margin: auto;
    }
    cite.author {
        color: #fff;
    }
    .video-container {
        display: flex;
        justify-content: center;
        video {
            width: 80%;
        }
    }

    @media screen and (max-width: $mobile-size) {
        .left,
        .right {
            max-width: 100%;
        }
        video {
            max-width: 85vw !important;
            width: 85vw !important;
        }
        .card-container .card {
            width: 145px;
            font-size: 0.75rem;
            .card-body {
                .title {
                    font-size: 0.75rem;
                }
            }
        }
        blockquote br {
            display: none;
        }
    }
}
