@import 'src/variables';
.team {
    // min-height: 500px;
    background: #fefefe;
    text-align: center;
    padding: 20px;

    h2 {
        color: $main-color;
    }
    h3 {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .card-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 880px;
        margin: auto;

        .card {
            padding: 0;
            padding-bottom: 20px;
            overflow: hidden;
            width: 30%;
            min-width: 200px;
            margin: 10px;
            flex-direction: column;
            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1;
                padding: 10px;
            }
            img {
                width: 100%;
            }
            .name {
                text-transform: uppercase;
                font-weight: 600;
            }
            .title {
                font-size: 0.875rem;
                margin-top: 5px;
            }
            .email {
                a {
                    font-size: 0.75rem;
                    margin-top: 5px;
                    color: $secondary-color;
                }
            }
        }
        &:last-child {
            & > div {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }
}
