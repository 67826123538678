@import 'src/variables';

.cookies-banner {
    position: fixed;
    bottom: 0;
    height: 300px;
    background: $secondary-color;
    box-shadow: 0px -6px 9px 4px #8282826e;
    display: flex;
    min-width: 100%;
    z-index: 10000;

    & > div {
        width: 100%;
        margin: auto 20px;
        &:last-child {
            min-width: 30%;
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                text-decoration-line: underline;
            }
            button {
                margin-top: 20px;
            }
        }
    }
}
