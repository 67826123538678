@import 'src/variables';
#footer {
    padding-top: 1.5em;
    background: $main-color;
    text-align: center;
    color: #c8e7f0;
    box-shadow: 1px -1px 14px #1c2135a3;

    .footer-body {
        display: flex;
        justify-content: space-around;

        & > div {
            flex: 1;
            &:last-child a {
                margin: 0 10px;
                font-size: 1.5rem;
                cursor: pointer;
            }
        }
    }

    h3 {
        color: #fff;
    }
    a {
        color: #c8e7f0;
        text-decoration: none;
    }
    .copyright {
        display: inline-block;
        color: #a7d9e7;
        font-size: 0.75em;
        margin-top: 20px;
        padding: 0;
        text-align: center;
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        padding: 1em 10em;
        white-space: nowrap;
    }
    @media screen and (max-width: $mobile-size) {
        & {
            margin-top: 40px;
            .footer-body {
                flex-direction: column;
                & > div {
                    margin-top: 40px;
                    &:first-child {
                        margin-top: 20px;
                    }
                    & > div {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
