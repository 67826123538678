$main-color: transparent linear-gradient(73deg, #0b1e3a, #111849 49%, #190e60) 0 0 no-repeat padding-box;
$secondary-color: #ed5929;
$color-border: #ddd;
$color-red: #ff7373;
$color-red-dark: #ff2727;
$color-grey: #ededed;
$color-label: #727a82;
$color-grey-light: #ddd;
$color-secondary-background: #f6755e;

$form_spacing: 30px;

$box-shadow: none;

$mobile-size: 768px;

@mixin formElement {
    position: relative;
    margin-top: $form_spacing;
    line-height: 1;
    color: #000;

    .bordered {
        border-bottom: 1px solid #000;
    }
    .error.bordered,
    .error .bordered {
        border-color: $color-red;
    }

    &.valid .bordered {
        border-color: $main-color;
    }

    &.no-border .bordered {
        border: none;
    }

    &.shadow {
        box-shadow: $box-shadow;
    }
    &.with-border {
        background-color: white;
        border: 1px solid $color-border;
        border-radius: 4px;
        padding: 11px 12px;
        padding-bottom: 4px;

        &.error {
            border-color: $color-red;
        }

        input,
        .input-wrapper {
            border: none;
        }
    }

    &.no-label {
        padding-top: 0;
    }

    &.with-icon {
        .input-wrapper {
            padding-right: 30px;
        }
    }

    &.no-margin {
        margin-top: 0;
    }

    .label {
        // @include labelInput;
    }

    input,
    textarea {
        width: 100%;
        font-weight: 600;

        &[disabled] {
            cursor: not-allowed;
        }
    }

    input {
        padding: 8px 0;
        min-height: 35px;
    }

    textarea {
        margin: 8px 0;
    }

    .error-message {
        // @include errorMessage;
    }
}
