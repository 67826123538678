@import 'src/variables';

.landing-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    hgroup,
    menu,
    section {
        display: block;
    }
    section {
        min-height: 80vh;
    }

    ol,
    ul {
        list-style: none;
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none;
    }

    /* Box Model */

    .content-wrapper {
        height: calc(100% - 5em);
        overflow: auto;
    }

    /* Containers */

    .container {
        margin-left: auto;
        margin-right: auto;
    }

    .container.\31 25\25 {
        width: 100%;
        max-width: 100em;
        min-width: 80em;
    }

    .container.\37 5\25 {
        width: 60em;
    }

    .container.\35 0\25 {
        width: 40em;
    }

    .container.\32 5\25 {
        width: 20em;
    }

    .container {
        width: 80em;
    }

    @media screen and (max-width: 1680px) {
        .container.\31 25\25 {
            width: 100%;
            max-width: 100em;
            min-width: 80em;
        }

        .container.\37 5\25 {
            width: 60em;
        }

        .container.\35 0\25 {
            width: 40em;
        }

        .container.\32 5\25 {
            width: 20em;
        }

        .container {
            width: 80em;
        }
    }

    @media screen and (max-width: 1280px) {
        .container.\31 25\25 {
            width: 100%;
            max-width: 81.25em;
            min-width: 65em;
        }

        .container.\37 5\25 {
            width: 48.75em;
        }

        .container.\35 0\25 {
            width: 32.5em;
        }

        .container.\32 5\25 {
            width: 16.25em;
        }

        .container {
            width: 65em;
        }
    }

    @media screen and (max-width: 980px) {
        .container.\31 25\25 {
            width: 100%;
            max-width: 112.5%;
            min-width: 90%;
        }

        .container.\37 5\25 {
            width: 67.5%;
        }

        .container.\35 0\25 {
            width: 45%;
        }

        .container.\32 5\25 {
            width: 22.5%;
        }

        .container {
            width: 90%;
        }
    }

    @media screen and (max-width: 736px) {
        .container.\31 25\25 {
            width: 100%;
            max-width: 112.5%;
            min-width: 90%;
        }

        .container.\37 5\25 {
            width: 67.5%;
        }

        .container.\35 0\25 {
            width: 45%;
        }

        .container.\32 5\25 {
            width: 22.5%;
        }

        .container {
            width: 90% !important;
        }
    }

    @media screen and (max-width: 480px) {
        .container.\31 25\25 {
            width: 100%;
            max-width: 112.5%;
            min-width: 90%;
        }

        .container.\37 5\25 {
            width: 67.5%;
        }

        .container.\35 0\25 {
            width: 45%;
        }

        .container.\32 5\25 {
            width: 22.5%;
        }

        .container {
            width: 90% !important;
        }
    }

    /* Basic */

    @-ms-viewport {
        width: device-width;
    }

    @media screen and (max-width: 480px) {
        html,
        body {
            min-width: 320px;
        }
    }

    body {
        background: #fff;
    }

    .inner {
        margin: 0 auto;
        max-width: 65em;
    }

    @media screen and (max-width: 1280px) {
        .inner {
            max-width: 90%;
        }
    }

    @media screen and (max-width: 980px) {
        .inner {
            max-width: 70%;
        }
    }

    @media screen and (max-width: 736px) {
        .inner {
            max-width: 85%;
        }
    }

    a:hover {
        text-decoration: none;
    }

    strong,
    b {
        color: #727a82;
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    p {
        margin: 0 0 2em 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #727a82;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        line-height: 1.5;
        margin: 0 0 1em 0;
    }

    h1 a,
    h2 a,
    h3 a,
    h4 a,
    h5 a,
    h6 a {
        color: inherit;
        text-decoration: none;
    }

    h1 {
        font-size: 2em;
        line-height: 1.3;
    }

    h2 {
        font-size: 1.75em;
        color: $secondary-color;
    }

    h3 {
        font-size: 1.25em;
    }

    h4 {
        font-size: 1em;
    }

    h5 {
        font-size: 0.9em;
    }

    h6 {
        font-size: 0.7em;
    }

    sub {
        font-size: 0.8em;
        position: relative;
        top: 0.5em;
    }

    sup {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }

    blockquote {
        font-style: italic;
        margin: 0 0 2em 0;
    }

    cite {
        display: block;
    }

    cite.author {
        font-weight: 400;
    }

    cite.company {
        font-size: 0.8em;
    }

    figure {
        margin: 0 0 2em 0;
    }

    code {
        background: rgba(144, 144, 144, 0.075);
        border-radius: 4px;
        border: solid 1px #dee1e3;
        font-family: 'Courier New', monospace;
        font-size: 0.9em;
        margin: 0 0.25em;
        padding: 0.25em 0.65em;
    }

    pre {
        -webkit-overflow-scrolling: touch;
        font-family: 'Courier New', monospace;
        font-size: 0.9em;
        margin: 0 0 2em 0;
    }

    pre code {
        display: block;
        line-height: 1.75;
        padding: 1em 1.5em;
        overflow-x: auto;
    }

    hr {
        border: 0;
        border-bottom: solid 1px #dee1e3;
        margin: 2em 0;
    }

    hr.major {
        margin: 3em 0;
    }

    .align-left {
        text-align: left;
    }

    .align-center {
        text-align: center;
    }

    .align-right {
        text-align: right;
    }

    /* Section/Article */

    section.special,
    article.special {
        text-align: center;
    }

    /* Form */

    form {
        margin: 0 0 2em 0;
    }

    label {
        color: #727a82;
        display: block;
        font-size: 0.9em;
        font-weight: 700;
    }

    .select-wrapper {
        text-decoration: none;
        display: block;
        position: relative;
    }

    .select-wrapper:before {
        content: '';
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-transform: none !important;
    }

    .select-wrapper:before {
        color: #dee1e3;
        display: block;
        height: 2.75em;
        line-height: 2.75em;
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 2.75em;
    }

    .select-wrapper select::-ms-expand {
        display: none;
    }

    textarea {
        padding: 0.75em 1em;
    }

    input[type='checkbox'],
    input[type='radio'] {
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance: none;
        appearance: none;
        display: block;
        float: left;
        margin-right: -2em;
        opacity: 0;
        width: 1em;
        z-index: -1;
    }

    input[type='checkbox'] + label,
    input[type='radio'] + label {
        text-decoration: none;
        color: #767d85;
        cursor: pointer;
        display: inline-block;
        font-size: 1em;
        font-weight: 300;
        padding-left: 2.4em;
        padding-right: 0.75em;
        position: relative;
    }

    input[type='checkbox'] + label:before,
    input[type='radio'] + label:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-transform: none !important;
    }

    input[type='checkbox'] + label:before,
    input[type='radio'] + label:before {
        background: rgba(144, 144, 144, 0.075);
        border-radius: 4px;
        border: solid 1px #dee1e3;
        content: '';
        display: inline-block;
        height: 1.65em;
        left: 0;
        line-height: 1.58125em;
        position: absolute;
        text-align: center;
        top: 0;
        width: 1.65em;
    }

    input[type='checkbox']:checked + label:before,
    input[type='radio']:checked + label:before {
        background: #f6755e;
        border-color: #f6755e;
        color: #ffffff;
        content: '\f00c';
    }

    input[type='checkbox']:focus + label:before,
    input[type='radio']:focus + label:before {
        border-color: #25a2c3;
        box-shadow: 0 0 0 1px #25a2c3;
    }

    input[type='checkbox'] + label:before {
        border-radius: 4px;
    }

    input[type='radio'] + label:before {
        border-radius: 100%;
    }

    ::-webkit-input-placeholder {
        color: #bbb !important;
        opacity: 1;
    }

    :-moz-placeholder {
        color: #bbb !important;
        opacity: 1;
    }

    ::-moz-placeholder {
        color: #bbb !important;
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: #bbb !important;
        opacity: 1;
    }

    .formerize-placeholder {
        color: #bbb !important;
        opacity: 1;
    }

    /* Box */

    .box {
        border-radius: 4px;
        border: solid 1px #dee1e3;
        margin-bottom: 2em;
        overflow: hidden;
    }

    .box > :last-child,
    .box > :last-child > :last-child,
    .box > :last-child > :last-child > :last-child {
        margin-bottom: 0;
    }

    .box .image.fit {
        margin: 0;
    }

    .box .content {
        padding: 1.5em;
    }

    .box.alt {
        border: 0;
        border-radius: 0;
        padding: 0;
    }

    .icon > .label {
        display: none;
    }

    /* Image */

    .image {
        border: 0;
        display: inline-block;
        position: relative;
    }

    .image img {
        display: block;
    }

    .image.left,
    .image.right {
        max-width: 40%;
    }

    .image.left img,
    .image.right img {
        width: 100%;
    }

    .image.left {
        float: left;
        padding: 0 1.5em 1em 0;
        top: 0.25em;
    }

    .image.right {
        float: right;
        padding: 0 0 1em 1.5em;
        top: 0.25em;
    }

    .image.fit {
        display: block;
        margin: 0 0 2em 0;
        width: 100%;
    }

    .image.fit img {
        width: 100%;
    }

    .image.round img {
        border-radius: 100%;
    }

    .image.main {
        display: block;
        margin: 0 0 3em 0;
        width: 100%;
    }

    .image.main img {
        width: 100%;
    }

    /* Flex */

    .flex {
        display: -ms-flexbox;
        -ms-flex-wrap: nowrap;
        -ms-flex-pack: justify;
        -ms-flex-align: stretch;
        display: -moz-flex;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -moz-align-items: stretch;
        -webkit-align-items: stretch;
        -ms-align-items: stretch;
        align-items: stretch;
    }

    .flex.flex-3 .flex-item {
        -ms-flex: 0 1 auto;
        -ms-flex-preferred-size: 30%;
        -moz-flex-basis: 30%;
        -webkit-flex-basis: 30%;
        -ms-flex-basis: 30%;
        flex-basis: 30%;
    }

    .flex.flex-3 .flex-item.left {
        text-align: right;
    }

    @media screen and (max-width: 980px) {
        .flex {
            -ms-flex-wrap: wrap;
            -moz-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .flex.flex-3 .flex-item {
            -ms-flex-preferred-size: 100%;
            -moz-flex-basis: 100%;
            -webkit-flex-basis: 100%;
            -ms-flex-basis: 100%;
            flex-basis: 100%;
        }

        .flex.flex-3 .flex-item.left {
            text-align: right;
        }
    }

    /* Wrapper */

    .wrapper {
        padding: 6em 0 4em 0;

        h2 {
            text-align: center;
            font-size: 2rem;
        }
        h3 {
            color: #fff;
        }
        p {
            font-weight: 700;
        }
        .truck-img {
            height: 300px;
            background-size: cover;
            width: 300px;
            border-radius: 300px;
            background-position: center;
        }
        h1,
        h4,
        h5 {
            color: #25a2c3;
        }
    }

    @media screen and (max-width: $mobile-size) {
        .wrapper {
            padding: 2em 20px;

            .truck-img {
                height: 200px;
                width: 200px;
                margin: auto;
            }
        }

        .flex.flex-3 .flex-item {
            &.left,
            &.right {
                text-align: center;
            }
        }
    }

    // MAp

    .map {
        position: relative;
        min-height: inherit;
    }
}
