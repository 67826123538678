@import 'src/variables';

.SideMenu {
    position: fixed;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2000;

    .background {
        position: fixed;
        opacity: 0;
        transition: all 400ms;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
    }
    .menu {
        overflow: auto;
        z-index: 2;
        position: fixed;
        height: 100%;
        width: auto;
        background-color: #ffffff;
        transition: all 400ms;
        .close-icon {
            position: absolute;
            top: 24px;
            font-size: 1.75rem;
            right: 20px;
            color: $secondary-color;
            z-index: 1;
        }
    }
    &.right .menu {
        right: 0;
        transform: translateX(100%);
    }

    display: block;
    &:not([class*='side']) {
        display: none;
    }
    &.side {
        &-exit-done {
            display: none;
        }
        &-enter-active,
        &-enter-done {
            .menu {
                transform: translateX(0%);
            }
            .background {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: $mobile-size) {
        & {
            .menu {
                left: 0;
            }
        }
    }
}
