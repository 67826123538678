@import 'src/variables';

.sponsor-section {
    background: #fefefe;
    padding: 40px 0;
    display: flex !important;
    position: relative;
    flex-wrap: wrap;
    text-align: center;

    p {
        font-weight: 500;
        max-width: 600px;
    }

    .left,
    .right {
        flex: 1;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 300px;
    }

    h2 {
        text-align: center;
    }
    .sponsor {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        & > div {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            margin: 20px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            transition: 0.5s;
            &:hover {
                box-shadow: 0 4px 30px 0 rgba(84, 84, 84, 0.34);
            }
            img {
                width: 60px;
                &.small {
                    width: 50px;
                }
            }
        }
    }
    @media screen and (max-width: 736px) {
        .sponsor {
            max-width: none;
        }
    }
}
