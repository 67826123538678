@import 'src/variables';

.parternship-section {
    background: #fefefe;
    padding: 40px 0;

    h2 {
        color: $main-color;
        text-align: center;
    }
    .parternship {
        max-width: 70%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > a {
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            margin: 20px;
            border-radius: 10px;
            text-align: center;
            display: flex;
            justify-content: center;
            transition: 0.5s;
            &:hover {
                box-shadow: 0 4px 30px 0 rgba(84, 84, 84, 0.34);
            }
            img {
                width: 90px;
            }
        }
    }
    @media screen and (max-width: 736px) {
        & .parternship {
            max-width: 90%;
        }
    }
}
