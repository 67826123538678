@import 'src/variables';

.login {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;

    .login-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 140px;
        overflow: auto;
        h2 {
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 1.35rem;
        }

        .container {
            min-height: 450px;
            flex: 1;
            padding: 20px;
            margin: 20px;
            display: flex;
            flex-direction: column;
            background: #1c2135e0;
            border-radius: 10px;
            box-shadow: 5px 5px 13px #000;
            form {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                flex: 1;
                input,
                button {
                    max-width: 300px;
                    width: 200px;
                }
                .Dropdown {
                    width: 300px;
                }

                .second-action {
                    color: #c4c4c4;
                    font-size: 0.875rem;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .item-type-subtitle {
        font-size: 12px;
        color: $color-label;
        letter-spacing: 0;
    }

    .input {
        margin-bottom: 20px;
        :last-child {
            margin: 0;
        }
    }

    .button-container {
        margin-top: 40px;
        width: 100%;
        text-align: center;
        button {
            width: 100%;
        }
    }

    @media screen and (max-width: $mobile-size) {
        .login-wrapper {
            flex-direction: column;
            overflow: auto;
            display: block;
            padding-top: 80px;
        }
    }
}
