@import 'src/variables';

.Modal {
    display: flex;
    flex-direction: column;
    outline: none;
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    justify-content: center;

    &.small {
        width: 300px;
        height: inherit;
        min-height: 200px;
    }
    &.medium {
        width: 400px;
        height: inherit;
    }
    &.full {
        width: calc(100vw - 40px);
        height: inherit;
    }
    .modal-title {
        padding: 20px;
        text-align: center;
        background: $main-color;
        color: $secondary-color;
        border-bottom: 3px solid $secondary-color;
        font-size: 1.25rem;
        display: flex;
        & > *:first-child,
        & > *:last-child {
            flex: 1;
        }
        .close-button {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
        }
    }

    .modal-content {
        padding: 20px;
        // flex: 1;
        background: $main-color;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}

.ReactModal__Overlay {
    z-index: 3000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff54;
    display: flex;
    justify-content: center;
    align-items: center;
}
