.reset-password-modal {
    .input {
        max-width: inherit;
        margin-top: 20px;
    }
    .button-container {
        margin-top: 40px;
        display: flex;
        justify-content: flex-end;
    }
}
