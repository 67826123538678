@import 'src/variables';

.input {
    display: flex;
    max-width: 300px;
    & > div {
        position: relative;
        height: 40px;
        .errors-input {
            position: absolute;
            left: 8px;
            bottom: 3px;
        }
    }
    label {
        background: $secondary-color;
        color: #fff;
        height: 40px;
        padding: 0 10px;
        border-radius: 25px 0 0 25px;
        display: flex;
        align-items: center;
        min-width: 100px;
        line-height: 16px;
        text-align: left;
    }
    input {
        border-radius: 0 25px 25px 0;
        border: 3px solid $secondary-color;
        font-size: 1.125rem;
        width: 100%;
        height: 100%;
    }

    &.classic {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        label {
            margin-bottom: 5px;
            color: $secondary-color;
            font-weight: 700;
        }
        input {
            padding: 8px 10px;
            border-radius: 8px;
            border: none;
        }
    }

    &.error {
        label {
            background: #f60000;
        }
        input {
            border-color: #f60000;
        }
    }
}
