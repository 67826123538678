@import 'src/variables';

header#header {
    width: 100%;
    // background: #fefefe;
    background: $main-color;
    cursor: default;
    height: 5em;
    // line-height: 8em;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.075);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 3px solid $secondary-color;

    .items-container {
        position: absolute;
        height: 0;
        font-size: 0;
        background: #fff;
        z-index: 1000;
        box-shadow: 4px 3px 8px #0000006e;
        border-radius: 4px;

        .item {
            height: 40px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            &:hover {
                background: #eee;
            }
        }
    }
    .menu {
        position: relative;
        &:hover {
            .items-container {
                height: initial;
                font-size: 1rem;
            }
        }
    }

    .left,
    .right {
        flex: 1;
    }
    .img-wrapper,
    .header-links {
        height: 3em;
    }

    h1 + p {
        font-size: 1.25em;
        margin-top: -1em;
    }
    p {
        color: #bbb;
        position: relative;
        margin: 0 0 1.5em 0;
    }

    h2 + p {
        font-size: 1.25em;
        margin-top: -1em;
    }
    h3 + p {
        font-size: 1.1em;
        margin-top: -0.8em;
    }
    h4 + p,
    h5 + p,
    h6 + p {
        font-size: 0.9em;
        margin-top: -0.6em;
    }

    div.right {
        display: none;
    }

    .right {
        display: flex;
        justify-content: flex-end;

        & > * {
            margin-left: 25px;
        }

        button {
            white-space: nowrap;
        }
        .menu-icon {
            color: $secondary-color;
            font-size: 1.5rem;
        }
    }

    nav {
        display: flex;
        align-items: center;

        img {
            &:first-child {
                height: 30px;
                margin-right: 10px;
            }
            &:last-child {
                height: 45px;
            }
        }
        &.left {
            a {
                display: flex;
                align-items: center;
                padding: 0 10px;
                border-radius: 4px;
            }
        }

        .button {
            padding: 0 2em;
            height: 3.25em;
            line-height: 3.25em;
        }
        a {
            text-decoration: none;
            display: inline-block;
        }
    }
}
nav {
    .link {
        text-decoration: none;
        color: $secondary-color;
        font-variant: all-small-caps;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;
        white-space: nowrap;
        letter-spacing: -0.5px;
    }
}
.img-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 10px;
    // margin-bottom: 3em;
    & > div {
        max-width: 60px;
        min-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img {
        max-height: 1.8em;
    }
}

.header-sidemenu {
    background: $main-color;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
        flex: 1;
    }

    .lang-selector {
        font-size: 1.125rem;
    }
    .img-wrapper {
        flex: 1;
    }
    nav {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        & > * {
            margin-top: 50px;
            text-align: center;
            text-decoration: none;
            font-size: 1.25rem;
        }

        .item .search {
            margin-left: 10px;
        }
        & > a,
        & > .item {
            color: $secondary-color !important;
        }
    }
}

@media screen and (max-width: $mobile-size) {
    #header {
        height: 5em;

        .img-wrapper,
        nav.right {
            display: none;
        }
        nav.left {
            left: 1em;

            img {
                height: 50px;
            }
        }

        div.right {
            display: inherit !important;
            cursor: pointer;
        }
    }
}
