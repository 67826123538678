@import 'src/variables';
.contact-form {
    text-align: center;
    min-height: 300px;
    & > * {
        margin-top: 20px;
        & > * {
            margin: auto;
        }
    }

    .input {
        margin: auto;
        margin-top: 20px;
    }
    .textarea {
        display: flex;
        flex-direction: column;
    }

    textarea {
        border: 3px solid $secondary-color;
        max-width: 300px;
        width: 100%;
        border-radius: 10px;
        font-family: sans-serif;
        padding: 5px;
    }
}
