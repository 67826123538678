@font-face {
  font-family: "font-icon";
  src: url("../../assets/icons/icons.eot") format("embedded-opentype"),
    url("../../assets/icons/icons.svg") format("svg"),
    url("../../assets/icons/icons.ttf") format("truetype"),
    url("../../assets/icons/icons.woff") format("woff");
}
.icon {
  font-family: "font-icon";
}
