@import 'src/variables';

.App {
    overflow: hidden;
    background: $main-color;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

.search-modal {
    .ReactModal__Overlay {
        background: #000000b3 !important;
    }
    .modal-content {
        background: none !important;
    }
}
