@import 'src/variables';

$h_item: 40px;
$h_filter: 45px;
$h_filter_small: 30px;
$num_elem: 5;

.Dropdown {
    @include formElement;
    outline: none;
    display: flex;
    min-width: 200px;
    max-width: 300px;

    label {
        background: $secondary-color;
        color: #fff;
        height: 40px;
        padding: 0 10px;
        border-radius: 20px 0 0 20px;
        display: flex;
        align-items: center;
        min-width: 100px;
        line-height: 16px;
    }

    .custom-input {
        width: 100%;
    }

    &.no-arrow .input-wrapper .value {
        padding-right: 0;
    }

    &.active {
        .items {
            max-height: $num_elem * $h_item;

            &.with-filter {
                max-height: $num_elem * $h_item + $h_filter;

                &.small {
                    max-height: $num_elem * $h_item + $h_filter_small;
                }
            }
        }
    }

    .input-wrapper {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        background: #fff;
        padding: 0 10px 0px 3px;
        flex: 1;
        border-radius: 0 20px 20px 0;
        border: 3px solid $secondary-color;
        font-size: 1.125rem;
        width: 100%;
        height: 40px;
        position: relative;

        .items {
            box-shadow: $box-shadow; //more shadow
            background-color: white;
            width: 100%;
            position: absolute;
            right: 0px;
            top: 100%;
            z-index: 30;
            overflow: hidden;
            max-height: 0;
            border-radius: 0 0 10px 10px;

            .filter {
                width: 100%;
                border-bottom: 1px solid #f2f2f2;
                background: #fff !important;
                max-width: none;
                height: $h_filter;
                line-height: $h_filter - 1px;
                background: #fff;
                padding: 0 20px !important;
                min-height: 35px !important;
            }

            .items-wrapper {
                position: relative;
                overflow: auto;
                scrollbar-width: thin;
                max-height: 150px;
                border-bottom: 1px solid $color-border;
            }

            .item {
                cursor: pointer;
                //   font-size: $font_size_form;
                padding: 10px;
                line-height: 1.4;
                position: relative;
                font-size: 1rem;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                .icon {
                    display: none;
                    right: 10px;
                    line-height: 1;
                }

                &.active {
                    background-color: rgba(0, 0, 0, 0.03);
                    font-weight: 600;

                    .icon {
                        display: block;
                    }
                }

                &.hoverArrow {
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }

            &.small {
                .item {
                    padding: 10px 12px;
                    font-size: 0.75rem;
                }

                .filter {
                    font-size: 0.75rem;
                    padding: 0 12px;
                    height: $h_filter_small;
                    line-height: $h_filter_small - 1px;
                }
            }
        }

        .arrow {
            color: $secondary-color;
            font-size: 1.5rem;
        }

        &.white {
            background-color: #fff;
        }

        .value {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            align-items: center;
            flex: 1;
            min-height: 35px;
            letter-spacing: normal;
            font-size: 1.125rem;
            .placeholder {
                color: #757575;
                font-weight: 600;
            }

            &.no-padding {
                padding: 0;
            }
        }
    }

    .arrow {
        display: flex;
        align-items: center;
        .add-icon {
            padding-left: 1px;
            pointer-events: inherit !important;
        }
        .add-icon,
        .indic {
            font-size: 0.875rem;
            background: $color-grey;
            color: $main-color;
            font-weight: 900;
            border-radius: 20px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }
    }

    .errors-input {
        position: absolute;
        left: 8px;
        bottom: 3px;
    }

    &.active {
        .input-wrapper {
            border-radius: 0 20px 0 0;
        }
        .items {
            max-height: $num_elem * $h_item;
            border: 3px solid $secondary-color;
            border-top: 0;
            width: calc(100% + 6px);
            right: -3px;

            &.with-filter {
                max-height: $num_elem * $h_item + $h_filter;
            }
        }

        .arrow-icon {
            transform: rotateX(180deg);
        }
    }
    .multiple {
        flex: 1;
        font-size: 0.875rem;
        .values-wrapper {
            display: flex;
            flex-wrap: wrap;

            > * {
                background: $secondary-color;
                border-radius: 4px;
                color: #fff;
                display: inline-block;
                margin: 5px;
                margin-left: 0px;
                padding: 5px;
                cursor: pointer;
                & > * {
                    display: flex;
                }
            }
        }
    }
    &.error {
        label {
            background: #f60000;
        }
        .input-wrapper {
            border-color: #f60000;
        }
    }
}
